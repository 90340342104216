import data from '../holiday.json'

export function beforeOrAt(date){
    date.setHours(24)
    const today = new Date()
    return today <= date
}

export function getFrom(){
    return `${data.fromYear}-${data.fromMonth}-${data.fromDay}`;
}

export function getTo(){
    return `${data.toYear}-${data.toMonth}-${data.toDay}`;
}

export function fromUntil(){
    var from = new Date(getFrom())
    from.setHours(0)
    var until = new Date(getTo())
    until.setHours(24)
    console.log(from);
    console.log(until);
    const today = new Date()
    return today >= from && today <= until
}


export function getFromToText(){
    if(getFrom() === getTo()){
        return `Der Laden ist am ${data.fromDay}.${data.fromMonth}.${data.fromYear} geschlossen.`
    }else{
        return `Der Laden ist vom ${data.fromDay}.${data.fromMonth} bis zum ${data.toDay}.${data.toMonth} geschlossen.`
    }
}
