import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { beforeOrAt, getTo, getFromToText } from "../functions/before";

class Kontakt extends Component {


  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 gnd-18">
            <h1>Kontakt</h1>
            <p>Beate Huber<br />
					Sonnenhalde 1a<br />
					77797 Ohlsbach<br />
					Telefon: 07803/980920<br />
					E-Mail: beate.huber@herzenston.de</p>
            <h2>Anfahrt</h2>
            <a href="https://g.page/herzenston?share" target="_blank" rel="noopener noreferrer">
                <img src="./images/Karte/Anfahrt.png" className="w-100p pb-10" alt="Anfahrt"
                  title="Anfahrt" />
            </a>
            {/*<iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2647.20211324622!2d7.991315815688357!3d48.43346057924818!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47912b37b1098a57%3A0xca0f3d391ad50f11!2sHerzenston!5e0!3m2!1sde!2sde!4v1489336077737"
            width="500" height="450" frameBorder="0" style={{ border: "0" }} allowFullScreen title="map"></iframe>*/}
          </div>

          <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 gnd-18">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 bb-1p">
              <h2>Öffnungszeiten</h2>
              <table>
                <tbody>
                  <tr><td className="col-md-6 pl-0"  style={{padding: "0 0 0.5em 0"}}>Donnerstag</td><td  style={{paddingBottom: "0.5em"}}>17-20 Uhr</td></tr>
                  <tr><td className="col-md-6 pl-0"  style={{padding: "0 0 0.5em 0"}}>Freitag</td><td  style={{paddingBottom: "0.5em"}}>14:30-18 Uhr</td></tr>
                  <tr><td className="col-md-6 pl-0"  style={{padding: "0 0 0.5em 0"}}>1. und 3. Samstag</td><td  style={{paddingBottom: "0.5em"}}>13-16 Uhr</td></tr>
                  <tr><td colSpan="2" className="col-md-12" style={{padding: "0 0 0.5em 0"}}><p>und nach telefonischer Vereinbarung</p></td></tr>
                </tbody>
              </table>
              { beforeOrAt(new Date(getTo())) ? <h2 style={{color: "red", fontSize: "1.3em"}} >{getFromToText()}</h2> : null}
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 bb-1p">
              <h2>Platzreservierung</h2>
              <p>Wenn du Keramik anmalen möchtest, empfehlen wir dir frühzeitig einen Termin zu sichern.<br />
              Sollte mal etwas dazwischen kommen, sag uns bitte so bald als möglich ab, damit der Platz weitervergeben werden kann.</p>
              <p>Freie Maltermine findet ihr hier:  <NavLink exact to="/termine"><b>Termine</b></NavLink></p>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 bb-1p">
              <h2>Sondertermine</h2>
              <p>Gerne gestalten wir mit euch euer ganz persönliches "Date": <br /><br />
              Für Geburtstag, Junggesellenabschied, Mädelsabend, Firmenevent oder Familien-/Ferienprogramm <br />
              bieten wir Termine auch außerhalb der regulären Öffnungszeiten an.</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Kontakt;